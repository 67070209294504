@import url('https://fonts.googleapis.com/css2?family=Irish+Grover&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.popup-input-group {
    display: flex;
    width: 100%;
    max-width: 500px;
    gap: 8px;
    align-items: flex-end;
}

.popup-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Name input container */
.popup-input-container:first-child {
    flex: 0.85;
}

/* Emoji picker container */
.popup-input-container:last-child {
    flex: 0.15;
}

.popup-label {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    margin-bottom: 4px;
    color: #304050;
}

.popup-name {
    width: 100%;
    height: 34px;
    padding: 2px 7px;
    background: var(--secondary-bg);
    border-radius: 42px;
    box-shadow: var(--input-shadow);
    border: none;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    color: #304050;
}

.popup-emoji-picker {
    position: relative;
}

.selected-emoji {
    width: 34px;
    height: 34px;
    padding: 1px;
    background: var(--secondary-bg);
    border-radius: 50%;
    box-shadow: var(--input-shadow);
    border: none;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.emoji-mart {
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 1000;
}

.picker-container {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1005;
}

.popup-comment {
    width: 100%;
    min-height: 80px;
    padding: 8px;
    margin-top: 12px;
    background: var(--secondary-bg);
    border-radius: 15px;
    box-shadow: var(--input-shadow);
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #304050;
    resize: vertical;
}

.popup-add-button {
    margin-top: 12px;
    padding: 8px 16px;
    font-family: 'Irish Grover', cursive;
    font-size: 16px;
}

/* Update the emoji list styling */
.emoji-list {
    position: absolute;
    right: -8px;
    top: calc(100% + 8px);
    background: white;
    border-radius: 16px; /* More rounded corners */
    box-shadow: 0 4px 12px rgba(0,0,0,0.15); /* Softer shadow */
    padding: 0; /* Remove default padding */
    z-index: 1010;
    width: 280px;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
}

/* Style the emoji search input */
.emoji-search {
    width: calc(100% - 24px); /* Account for container padding */
    margin: 12px;
    padding: 8px 12px;
    border: 1px solid #eaeaea;
    border-radius: 20px; /* Rounded search input */
    font-size: 14px;
    background: #f8f8f8;
}

.emoji-search:focus {
    outline: none;
    border-color: #ccc;
    background: white;
}

/* Update category styling */
.emoji-category {
    margin-bottom: 12px;
    background: white;
}

/* Update category title to stick properly */
.emoji-category-title {
    font-size: 12px;
    color: #666;
    margin: 0; /* Remove margin */
    padding: 8px 12px;
    text-transform: capitalize;
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
    font-weight: 500;
    border-bottom: 1px solid #eaeaea; /* Optional: adds a subtle separator */
}

/* Adjust category content padding */
.emoji-category-content {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 2px;
    padding: 8px 12px;
}

.emoji-option {
    cursor: pointer;
    padding: 6px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    border-radius: 8px; /* Rounded emoji containers */
}

.emoji-option:hover {
    background: #f0f0f0;
    transform: scale(1.1);
}

/* Update media queries */
@media screen and (max-width: 480px) {
    .emoji-list {
        width: 260px;
        right: -113px; /* Center it better on mobile */
        border-radius: 12px;
    }
    
    .emoji-category-content {
        grid-template-columns: repeat(5, 1fr); /* Slightly fewer columns on mobile */
    }
}

@media screen and (max-width: 320px) {
    .emoji-list {
        width: 240px;
        right: -103px;
    }
    
    .emoji-category-content {
        grid-template-columns: repeat(4, 1fr);
    }
}
