/* Add dark mode variables */
:root {
  --table-bg: #dcdee0;
  --cell-bg: #EFF0F0;
  --text-color: #0d1a26;
  --button-bg: #EFF0F0;
  --paper-bg: #ffffff;
}

[data-theme='dark'] {
  --table-bg: #2c3e50;
  --cell-bg: #34495e;
  --text-color: #ecf0f1;
  --button-bg: #445566;
  --paper-bg: #1a1a1a;
}

/* Update Paper component background */
.MuiPaper-root {
  background-color: var(--paper-bg) !important;
}

.table-container {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  background: var(--table-bg) !important;
  border-radius: 10px !important;
  overflow: hidden !important;
}

.table-header {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
}

.table-header-cell {
  flex: 1 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: var(--table-bg) !important;
  height: 18px !important;
  padding: 0 15px !important;
  white-space: nowrap !important;
}

.table-header-text {
  color: var(--text-color) !important;
  font-size: 16px !important;
  font-family: 'Montserrat' !important;
  font-weight: 1000 !important;
  text-decoration: underline !important;
  line-height: 18px !important;
  word-wrap: break-word !important;
  text-align: center !important;
}

/* Update table cells */
.MuiTableCell-root {
  background-color: var(--cell-bg) !important;
  color: var(--text-color) !important;
  border-bottom: 1px solid var(--table-bg) !important;
}

/* Update table header cells */
.MuiTableCell-head {
  background-color: var(--table-bg) !important;
  color: var(--text-color) !important;
  font-weight: bold !important;
}

/* Update add katsching button */
.add-katsching-button {
  background: var(--button-bg) !important;
  color: var(--text-color) !important;
}

.katsching-container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  width: 100% !important;
  color: var(--text-color) !important;
}

.katsching-counter {
  margin-right: 4px !important;
  text-align: right !important;
  min-width: 20px !important;
  display: inline-block !important;
}

.add-katsching-button {
  background: var(--button-bg) !important;
  border-radius: 30% !important;
  min-width: 24px !important;
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  margin-left: 4px !important;
  color: var(--text-color) !important;
}

/* Update icons color */
.MuiSvgIcon-root {
  color: var(--text-color) !important;
}

/* Add transition for smooth theme switching */
.MuiTableCell-root,
.MuiTableCell-head,
.add-katsching-button,
.MuiSvgIcon-root {
  transition: background-color 0.3s ease, color 0.3s ease !important;
}

.table-row {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
}

.table-cell {
  flex: 1 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: var(--table-bg) !important;
  padding: 0 15px !important;
  font-family: 'Montserrat', sans-serif !important;
  color: var(--text-color) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: center !important;
  min-width: 0 !important;
}

.table-header-cell,
.table-cell {
  justify-content: center !important;
  text-align: center !important;
}

.katsching-counter {
  margin-right: 4px !important;
  text-align: right !important;
  min-width: 20px !important;
  display: inline-block !important;
}

@media (max-width: 768px) {
  .MuiTableCell-root {
    font-size: 12px !important;
    padding: 6px !important;
  }
  
  .table-cell {
    padding: 0 8px !important;
    font-size: 14px !important;
  }
  
  .actions-cell {
    min-width: 40px !important;
  }
}

.MuiTableContainer-root {
  width: 100% !important;
  height: 100% !important;
  overflow-x: hidden !important;
}

.MuiTable-root {
  width: 100% !important;
  min-width: unset !important;
  table-layout: fixed !important;
}

.MuiTableCell-root {
  padding: 8px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.MuiTableCell-head {
  font-family: 'Montserrat', cursive !important;
  background-color: var(--table-bg) !important;
}

.MuiTableCell-body {
  font-family: 'Montserrat', sans-serif !important;
}

.player-name-cell {
  flex: 2 !important;
  justify-content: flex-start !important;
  width: 30% !important;
}

.last-katsching-cell {
  flex: 2 !important;
  width: 35% !important;
}

.katschings-cell {
  flex: 1 !important;
  width: 25% !important;
}

.actions-cell {
  flex: 0.5 !important;
  min-width: 60px !important;
  width: 10% !important;
  padding: 0 4px !important;
}

.MuiTableHead-root {
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
  background-color: var(--table-bg) !important;
}

.funny-button {
  transition: all 0.3s ease !important;
}

.funny-button:hover {
  transform: scale(1.2) rotate(15deg) !important;
  background-color: #3be2ff !important;
  box-shadow: 0 0 10px rgba(255, 235, 59, 0.7) !important;
}

.funny-button:active {
  transform: scale(0.9) rotate(-15deg) !important;
  background-color: #20a1cc !important;
}

@keyframes wiggle {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-5deg); }
  50% { transform: rotate(5deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.funny-button:focus {
  animation: wiggle 0.5s ease-in-out;
  outline: none !important;
}
