/* Add dark mode variables */
:root {
  --rules-text: #304050;
  --rules-bg: rgba(245, 245, 245, 0);
  --rules-card-bg: rgba(255, 255, 255, 0);
}

[data-theme='dark'] {
  --rules-text: #ecf0f1;
  --rules-bg: rgba(44, 62, 80, 0);
  --rules-card-bg: rgba(52, 73, 94, 0);
}

.rulebook-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.rules-text {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--rules-text);
  text-align: center;
}

.rules-card,
.suggestion-card,
.suggestions-card {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--rules-bg);
}

.rules-card h3,
.suggestion-card h3,
.suggestions-card h3 {
  margin-top: 0;
  color: var(--rules-text);
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: var(--rules-card-bg);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--rules-text);
}

.delete-button,
.approve-button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 12px;
}

.delete-button {
  background-color: #ff4d4d;
  color: white;
}

.approve-button {
  background-color: #4caf50;
  color: white;
}

.suggestion-card TextField {
  margin-bottom: 10px;
}
