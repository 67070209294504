@import url('https://fonts.googleapis.com/css2?family=Irish+Grover&family=Montserrat&display=swap');

:root {
    --primary-bg: #BCECF5;
    --secondary-bg: #EFF0F0;
    --overlay-bg: rgba(0, 0, 0, 0.5);
    --text-color: #304050;
    --font-family-main: 'Montserrat', sans-serif;
    --font-family-secondary: 'Irish Grover', cursive;
    --font-size-main: 19px;
    --line-height-main: 1.5;
    --box-shadow: 6px 5px 3px rgba(0, 0, 0, 0.25);
    --input-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    --success-color: #1EAB35;
}

[data-theme='dark'] {
    --primary-bg: #2c3e50;
    --secondary-bg: #34495e;
    --overlay-bg: rgba(0, 0, 0, 0.7);
    --text-color: #ecf0f1;
    --success-color: #2ecc71;
}

.popup-overlay {
    position: fixed;
    inset: 0;
    background: var(--overlay-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
}

.popup-overlay.visible {
    opacity: 1;
    visibility: visible;
}

.popup-overlay.closing {
    opacity: 0;
    transition: opacity 0.2s ease, visibility 0s 0.2s;
}

.popup-container {
    max-width: 300px;
    width: 90%;
    background: var(--primary-bg);
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    overflow: hidden;
    transform: scale(0.5);
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1), opacity 0.3s ease;
}

.popup-overlay.visible .popup-container {
    animation: popBounce 0.4s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

.popup-overlay.closing .popup-container {
    animation: popClose 0.3s ease-in-out forwards;
}

.popup-content {
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: var(--primary-bg);
}

.popup-subtitle {
    color: var(--text-color);
    font: 500 24px/1.3 var(--font-family-secondary);
    text-align: center;
    margin-bottom: 10px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.popup-counter-group {
    display: flex;
    padding: 1px 8px;
    gap: 16px;
    align-items: center;
}

.popup-counter {
    width: 20%;
    padding: 2px 7px;
    background: var(--secondary-bg);
    border-radius: 42px;
    box-shadow: var(--input-shadow);
    border: none;
    cursor: pointer;
}

.popup-counter::before {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    top: 2px;
    border: 4px #89949F solid;
    border-radius: 50%;
}

.popup-counter.minus span,
.popup-counter.plus span {
    position: relative;
    z-index: 1;
    color: #89949F;
}

.popup-counter-value,
.popup-comment {
    padding: 8px 10px;
    background: var(--secondary-bg);
    border-radius: 42px;
    box-shadow: var(--input-shadow);
    font-family: var(--font-family-main);
}

.popup-counter-value {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.popup-comment {
    width: 100%;
    min-height: 80px;
    padding: 12px;
    background: var(--secondary-bg);
    border-radius: 15px;
    box-shadow: var(--input-shadow);
    font-family: var(--font-family-main);
    font-size: 16px;
    color: var(--text-color);
    border: none;
    resize: vertical;
    overflow-y: auto;
    transition: box-shadow 0.3s ease;
    color: var(--text-color);
}

.popup-comment:focus {
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
    outline: none;
}

.popup-add-button {
    width: 100%;
    padding: 12px;
    background: #1EAB35; /* Use a solid color for better performance */
    border-radius: 10px;
    color: white;
    font: 600 18px var(--font-family-secondary);
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
}

.popup-add-button:hover {
    background-color: #1AA55A; /* Slightly change the color on hover */
    transform: scale(1.02); /* Slightly enlarge on hover */
}

.popup-add-button:active {
    transform: scale(0.98); /* Slightly shrink on active */
}

@keyframes popBounce {
    0% { transform: scale(0.5); opacity: 0; }
    70% { transform: scale(1.05); opacity: 1; }
    85% { transform: scale(0.98); opacity: 1; }
    100% { transform: scale(1); opacity: 1; }
}

@keyframes popClose {
    0% { transform: scale(1) rotate(0); opacity: 1; }
    100% { transform: scale(0.5) rotate(-10deg); opacity: 0; }
}

.success-message {
    color: var(--success-color);
    margin-top: 10px;
    font-weight: bold;
}
