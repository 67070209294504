/* Add dark mode variables */
:root {
  --stats-bg: #f5f5f5;
  --stats-text: #333;
  --stats-border: #ccc;
  --tooltip-bg: rgba(255, 255, 255, 0.8);
  --button-bg: #f0f0f0;
  --button-hover: #e0e0e0;
  --slider-bg: #d3d3d3;
  --slider-thumb: #4CAF50;
}

[data-theme='dark'] {
  --stats-bg: #2c3e50;
  --stats-text: #ecf0f1;
  --stats-border: #4a4a4a;
  --tooltip-bg: rgba(52, 73, 94, 0.9);
  --button-bg: #34495e;
  --button-hover: #445566;
  --slider-bg: #34495e;
  --slider-thumb: #2ecc71;
}

/* Add at the root level to prevent horizontal scrolling globally within the container */
.statistics-container {
  width: 95%;
  min-height: 100px;
  padding: 20px;
  box-sizing: border-box;
  background-color: var(--stats-bg);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  max-width: 100%; /* Ensure container doesn't exceed viewport width */
}

/* Remove fixed height media queries */
@media screen and (max-width: 768px) {
  .statistics-container {
    width: 98%;
    padding: 10px;
    margin: 10px auto;
  }

  .chart-container {
    margin-bottom: 30px;
  }
}

/* Update bottom section to be more flexible */
.bottom-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  min-height: auto; /* Remove fixed min-height */
  margin: 20px 0;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

/* Make pie chart container more responsive */
.pie-chart-container {
  flex: 1;
  min-height: 300px;
  height: 300px; /* Set fixed height */
  position: relative; /* Add position relative */
  margin-bottom: 20px; /* Add margin bottom for spacing */
}

/* Adjust mobile layout */
@media screen and (max-width: 768px) {
  .bottom-section {
    flex-direction: column;
    align-items: center;
  }

  .pie-chart-container {
    width: 100%;
    height: 250px; /* Slightly smaller height for mobile */
    margin-bottom: 15px;
  }

  .interesting-stats {
    width: 100%;
  }
}

/* Remove other fixed height declarations that might conflict */
@media screen and (max-width: 1200px), 
       screen and (max-width: 992px),
       screen and (max-width: 576px) {
  .statistics-container {
    height: auto;
  }
}

h1 {
  text-align: center;
  color: var(--stats-text);
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
}

.content-wrapper {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.player-filter {
  width: 200px;
  overflow-y: auto;
  padding-right: 5px;
  margin-right: 5px;
  border-right: 1px solid #ccc;
}

.player-filter label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}

.player-filter input[type="checkbox"] {
  margin-right: 5px;
}

.katsching-count {
  margin-left: auto;
  font-weight: bold;
}

.chart-container {
  width: 100%;
  height: calc(100% - 120px); /* Increase the subtracted value to make room for the legend */
  margin-bottom: 60px; /* Increase bottom margin */
}

.custom-tooltip {
  background-color: var(--tooltip-bg);
  border: 1px solid var(--stats-border);
  border-radius: 4px;
  padding: 10px;
  color: var(--stats-text);
}

.custom-tooltip .label {
  margin: 0;
  font-weight: bold;
}

.custom-tooltip p {
  margin: 0;
  padding: 3px 0;
}

.recharts-default-tooltip {
  background-color: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  padding: 10px !important;
}

.recharts-tooltip-label {
  font-weight: bold !important;
  margin-bottom: 5px !important;
}

.recharts-tooltip-item {
  padding: 2px 0 !important;
}

.recharts-legend-wrapper {
  bottom: -40px !important; /* Move the legend down */
}

.recharts-default-legend {
  text-align: center;
}

.recharts-legend-item {
  cursor: pointer !important;
  margin: 0 10px !important; /* Add some horizontal spacing between legend items */
}

.axis-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.axis-controls button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.axis-controls button:hover {
  background-color: #e0e0e0;
}

.recharts-tooltip-wrapper {
  transition: none !important;
}

.custom-legend {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  gap: 8px; /* Add small gap between items */
  margin: 5px 0; /* Reduce vertical margin */
}

.legend-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease;
  padding: 2px 5px; /* Reduce padding */
  background-color: var(--stats-bg);
  border-radius: 4px;
  border: 1px solid var(--stats-border);
  font-size: 11px; /* Slightly smaller font */
}

.legend-item.hidden {
  opacity: 0.5;
}

.legend-color {
  width: 8px; /* Smaller color indicator */
  height: 8px;
  margin-right: 4px; /* Reduce spacing */
  border-radius: 50%;
}

.legend-text {
  color: var(--stats-text);
  font-size: 11px;
  font-weight: normal; /* Changed from bold */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* Limit maximum width of legend text */
}

.toggle-all-button {
  background-color: var(--button-bg);
  border: 1px solid var(--stats-border);
  color: var(--stats-text);
  margin-bottom: 5px; /* Reduced margin */
  padding: 3px 8px; /* Smaller padding */
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px; /* Smaller font size */
}

/* Update mobile styles for legend */
@media screen and (max-width: 768px) {
  .custom-legend {
    gap: 4px; /* Even smaller gap on mobile */
  }

  .legend-item {
    padding: 1px 4px;
    font-size: 10px;
  }

  .legend-color {
    width: 6px;
    height: 6px;
    margin-right: 3px;
  }

  .legend-text {
    font-size: 10px;
  }

  .toggle-all-button {
    padding: 2px 6px;
    font-size: 11px;
  }
}

.chart-resize-control {
  margin: 10px auto;
  text-align: center;
  width: 80%;
  max-width: 300px;
}

.chart-resize-slider {
  width: 100%;
  -webkit-appearance: none;
  height: 10px;
  border-radius: 5px;
  background: var(--slider-bg);
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

.chart-resize-slider:hover {
  opacity: 1;
}

.chart-resize-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--slider-thumb);
  cursor: pointer;
}

.chart-resize-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--slider-thumb);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .chart-resize-control {
    width: 90%;
  }
}

/* Improve the appearance of the range input for better cross-browser compatibility */
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

input[type="range"]:hover {
  opacity: 1;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 576px) {
  .statistics-container {
    padding: 5px;
  }

  .bottom-section {
    gap: 15px;
  }

  .pie-chart-container {
    height: 250px; /* Slightly smaller height for very small screens */
  }
}

/* Add back the interesting-stats styling */
.interesting-stats {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.stat-card {
  background-color: var(--button-bg);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  transition: transform 0.2s;
}

.stat-card:hover {
  transform: translateY(-2px);
}

.stat-title {
  font-size: 0.9rem;
  color: var(--stats-text);
  margin-bottom: 5px;
}

.stat-value {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--stats-text);
}

/* Update mobile styling for interesting-stats */
@media screen and (max-width: 768px) {
  .interesting-stats {
    width: 100%;
    padding: 10px;
  }

  .stat-card {
    margin-bottom: 10px;
  }
}

/* Ensure all flex containers respect container width */
.content-wrapper,
.bottom-section,
.chart-container,
.custom-legend {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

/* Ensure tooltips don't cause horizontal scrolling */
.custom-tooltip,
.recharts-default-tooltip {
  max-width: 90vw;
  box-sizing: border-box;
  word-break: break-word;
}

/* Adjust mobile styles to prevent overflow */
@media screen and (max-width: 768px) {
  .statistics-container {
    width: 98%;
    padding: 10px;
    margin: 10px auto;
  }

  .legend-text {
    max-width: 100px; /* Smaller max-width for mobile */
  }
}

/* Update pie chart container styles */
.pie-chart-container {
  flex: 1;
  width: 100%;
  min-width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default; /* Remove pointer cursor */
}

/* Add styles to prevent clickable appearance */
.recharts-pie-sector {
  cursor: default !important;
}

.recharts-pie-label-text {
  cursor: default !important;
  font-size: 12px !important;
}

@media screen and (max-width: 768px) {
  .pie-chart-container {
    min-width: 250px;
    height: 250px;
  }
  
  .recharts-pie-label-text {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 576px) {
  .pie-chart-container {
    min-width: 200px;
    height: 200px;
  }
}

/* Remove focus outline from chart elements */
.recharts-wrapper {
  outline: none !important;
}

.recharts-surface {
  outline: none !important;
}

/* Remove focus outline from all chart elements */
.recharts-layer {
  outline: none !important;
}

.recharts-pie {
  outline: none !important;
}

.recharts-pie-sector {
  outline: none !important;
}

/* More aggressive focus outline removal */
.recharts-wrapper *,
.recharts-surface *,
.recharts-layer *,
.recharts-pie *,
.recharts-pie-sector *,
.recharts-default-tooltip *,
.recharts-tooltip-wrapper * {
  outline: none !important;
  -webkit-tap-highlight-color: transparent !important;
  user-select: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
}

/* Remove focus ring from SVG elements */
svg:focus {
  outline: none !important;
}

/* Remove focus styles from the container */
.pie-chart-container * {
  outline: none !important;
  -webkit-tap-highlight-color: transparent !important;
}
