/* Add dark mode variables */
:root {
    --table-bg: #dcdee0;
    --cell-bg: #EFF0F0;
    --text-color: #0d1a26;
    --delete-button: #ff4d4f;
    --delete-button-hover: #ff7875;
    --paper-bg: #ffffff;
}

[data-theme='dark'] {
    --table-bg: #2c3e50;
    --cell-bg: #34495e;
    --text-color: #ecf0f1;
    --delete-button: #e74c3c;
    --delete-button-hover: #c0392b;
    --paper-bg: #1a1a1a;
}

/* Update Paper component background */
.MuiPaper-root {
    background-color: var(--paper-bg) !important;
}

.history-table-container {
    width: 91% !important;
    height: 100% !important;
    padding: 0 !important;
    background: var(--table-bg) !important;
    border-radius: 10px !important;
    overflow: hidden !important;
}

.MuiTableContainer-root {
    width: 100% !important;
    height: 100% !important;
    overflow: auto !important;
}

.MuiTable-root {
    width: 100% !important;
    height: 100% !important;
    table-layout: fixed !important;
}

/* Header Styles */
.MuiTableHead-root {
    position: sticky !important;
    top: 0 !important;
    z-index: 1 !important;
}

.MuiTableCell-head {
    font-weight: 700 !important;
    font-family: 'Montserrat', cursive !important;
    background-color: var(--table-bg) !important;
    color: var(--text-color) !important;
    padding: 12px 15px !important;
    white-space: nowrap !important;
}

/* Cell Styles */
.MuiTableCell-root {
    background-color: var(--cell-bg) !important;
    color: var(--text-color) !important;
    border-bottom: 1px solid var(--table-bg) !important;
    font-family: 'Montserrat', sans-serif !important;
    padding: 12px 15px !important;
    vertical-align: top !important;
}

/* Column Widths */
.time-cell {
    width: 15% !important;
    min-width: 100px !important; /* Reduced from 120px since we allow two lines */
}

.event-cell {
    width: 35% !important;
}

.comment-cell {
    width: 45% !important;
}

.actions-cell {
    width: 5% !important;
    min-width: 50px !important;
}

/* Text Wrapper for Event and Comment cells */
.text-wrapper {
    color: var(--text-color) !important;
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    word-break: break-word !important;
    hyphens: auto !important;
    line-height: 1.4 !important;
    max-height: none !important;
}

/* Delete Button Styles */
.delete-button {
    color: var(--delete-button) !important;
    transition: all 0.3s ease !important;
}

.delete-button:hover {
    color: var(--delete-button-hover) !important;
    transform: scale(1.1) !important;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .MuiTableCell-root {
        font-size: 12px !important;
        padding: 8px !important;
    }

    .time-cell {
        width: 20% !important;
        min-width: 80px !important;
    }

    .event-cell {
        width: 35% !important;
    }

    .comment-cell {
        width: 40% !important;
    }

    .actions-cell {
        width: 5% !important;
        min-width: 40px !important;
    }
}

/* Time Cell Specific Styles */
.time-wrapper {
    color: var(--text-color) !important;
    font-family: 'Montserrat', monospace !important;
    white-space: normal !important; /* Changed from wrap to normal */
    overflow: visible !important;
    font-size: 14px !important;
    padding: 2px 4px !important;
    line-height: 1.4 !important; /* Increased from 1.2 to 1.4 for better readability */
    text-align: left !important;
}

/* Ensure time is readable on small screens */
@media (max-width: 768px) {
    .time-wrapper {
        font-size: 12px !important;
        min-width: 80px !important; /* Reduced from 90px */
        line-height: 1.3 !important;
    }
    
    .time-cell {
        padding: 8px 4px !important;
        min-width: 90px !important; /* Reduced from 100px */
    }
}

/* Add transition for smooth theme switching */
.MuiTableCell-root,
.MuiTableCell-head,
.text-wrapper,
.time-wrapper,
.delete-button {
    transition: background-color 0.3s ease, color 0.3s ease !important;
}
