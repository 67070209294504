@import url('https://fonts.googleapis.com/css2?family=Irish+Grover&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root {
  --primary-color: #DCDEE0;
  --secondary-color: #89949F;
  --gradient-top-bottom: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
  --text-color: #0d1a26;
  --background-color: #ffffff;
  --table-bg: #dcdee0;
  --cell-bg: #EFF0F0;
  --button-bg: #EFF0F0;
  --button-text: #0d1a26;
  --border-color: #ccc;
  --popup-bg: #BCECF5;
  --popup-input-bg: #EFF0F0;
  --popup-text: #304050;
  --success-color: #1EAB35;
  --delete-button: #ff4d4f;
  --delete-button-hover: #ff7875;
}

[data-theme='dark'] {
  --primary-color: #2c3e50;
  --secondary-color: #34495e;
  --gradient-top-bottom: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
  --text-color: #ecf0f1;
  --background-color: #1a1a1a;
  --table-bg: #2c3e50;
  --cell-bg: #34495e;
  --button-bg: #445566;
  --button-text: #ecf0f1;
  --border-color: #4a4a4a;
  --popup-bg: #2c3e50;
  --popup-input-bg: #34495e;
  --popup-text: #ecf0f1;
  --success-color: #2ecc71;
  --delete-button: #e74c3c;
  --delete-button-hover: #c0392b;
}

body {
  background-image: var(--gradient-top-bottom) !important;
  color: var(--text-color) !important;
}


.App-header {
  display: flex;           /* Enables flexbox layout */
  align-items: center;     /* Vertically centers the items */
  justify-content: center; /* Centers the items horizontally */
  text-align: center;      /* Keeps text centered within its container */
  padding: 10px;
  font-size: calc(10px + 2vmin) !important;
  padding-bottom: 0px !important;
}

.App-title {
  font-family: 'Irish Grover', cursive;
  font-size: 2.5rem;
  margin-right: 20px;      /* Adds space between the title and the logo */
}
.App {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative !important; /* Add this to contain absolute positioned elements */
  width: 100% !important; /* Ensure full width */
  min-height: 100vh !important; /* Ensure full height */
}

.App-logo {
  height: 12vmin  !important;
  pointer-events: visibleFill  !important;
  animation: App-logo-spin infinite 10s linear  !important;
  padding-bottom: 0px !important;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg) ;
  }
  to {
    transform: rotate(360deg) ;
  }
}

.mainscreen-card {
  background-color: rgba(255, 255, 255, 0) !important;
}

/* Table Styling */
.table-container {
  width: 95vw !important;
  height: 50hw !important;
  padding: 11px 20px !important;
  background: var(--table-bg) !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  gap: 1px !important;
  border-radius: 10px !important; /* add round edges */
  overflow-x: auto;
}

.table-header {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
}

.table-header-cell {
  flex: 1 !important;
  display: flex !important;
  justify-content: center !important; /* Center the contents */
  align-items: center !important; /* Center the contents */
  background: var(--cell-bg) !important;
  height: 18px !important;
  padding: 0 15px !important;
  white-space: nowrap !important; /* Prevent text from breaking into multiple lines */
  color: var(--text-color) !important;
}

.table-header-cell:last-child {
  width: 0px !important;
}

.table-header-text {
  text-align: center !important;
  color: var(--text-color) !important;
  font-size: 16px !important;
  font-family: 'Irish Grover' !important;
  font-weight: 700 !important;
  text-decoration: underline !important;
  line-height: 18px !important;
  word-wrap: break-word !important;
}

/* Table Styles */
.table-row {
  display: flex !important;
  align-items: center !important; /* Center the contents */
  width: 100% !important;
}

.table-cell {
  flex: 1 !important; /* This makes the cells take up equal space */
  display: flex !important;
  justify-content: center !important; /* Center the contents */
  align-items: center !important; /* Center the contents */
  background: var(--cell-bg) !important;
  padding: 0 15px !important;
  font-family: 'Montserrat' !important;
  color: var(--text-color) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  word-wrap: normal !important;
  white-space: nowrap !important; /* Prevent text from breaking into multiple lines */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  
}

.table-header-cell:first-child {
  justify-content: flex-start !important; /* Align the first cell to the left */
}

.table-cell:first-child {
  justify-content: flex-start !important; /* Align the first cell to the left */
}
/* Add Player Button Styling */
.add-player-button-container {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 10px !important;
  align-items: center !important;
  gap: 10px !important; /* Add consistent spacing between elements */
  flex-wrap: wrap !important; /* Allow wrapping on smaller screens */
  padding: 0 10px !important;
}

.add-player-button {
  height: 40px !important;
  padding: 0 20px !important;
  margin-top: 20px !important;
  background: var(--button-bg) !important;
  background: #EFF0F0 !important;
  border-radius: 23px !important;
  color: black !important;
  font-size: 14px !important;
  font-family: 'Montserrat' !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  white-space: nowrap !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.copy-to-clipboard-button {
  height: 40px !important;
  padding: 0 20px !important;
  margin-top: 20px !important;
  background: #EFF0F0 !important;
  border-radius: 23px !important;
  color: black !important;
  font-size: 14px !important;
  font-family: 'Montserrat' !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  white-space: nowrap !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.update-button { 
  justify-content: center !important;
  border: none !important;
  font-size: 15px !important;
  height: auto !important;
  font-family: "Montserrat" !important;
  font-weight: 300 !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 30%;
}

/* Add this to your existing CSS */
.katsching-container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

div.katsching-counter{
  align-items: left !important;
  width: 10px !important;
  padding-right: 5px !important;
}
.add-katsching-button {
  background: #EFF0F0 !important;
  border-radius: 30% !important;
  width: 24px !important;
  height: 24px !important;
  display: flex !important;
  justify-content: rechts !important; /* Center the contents */
  align-items: center !important; /* Center the contents */
  cursor: pointer !important;
  margin-left: 0 !important;
}



/* History Table Styling */
.history-table-container {
  width: 95vw !important;
  padding: 11px 20px !important;
  background: #dcdee0 !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important; /* Changed to space-between */
  align-items: flex-start !important;
  gap: 1px !important;
  border-radius: 10px !important;
  margin-top: 20px !important;
  z-index: 1 !important;
  overflow: hidden !important;
  max-height: 300px !important;
}

.history-table-header {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
}

.history-table-header-cell {
  flex: 1 !important;
  display: flex !important;
  justify-content: center !important; /* Center the contents */
  align-items: center !important; /* Center the contents */
  background: #dcdee0 !important;
  height: 18px !important;
  padding: 0 15px !important;
  white-space: nowrap !important; /* Prevent text from breaking into multiple lines */
}

.history-table-header-cell:first-child {
  justify-content: flex-start !important; /* Align the first cell to the left */
  flex: 2 !important;
}


.history-table-header-cell:last-child {
  justify-content: flex-end !important; /* Align the last cell to the right */
}

.history-table-header-text {
  text-align: center !important;
  color: #0d1a26 !important;
  font-size: 12px !important;
  font-family: 'Irish Grover' !important;
  font-weight: 700 !important;
  text-decoration: underline !important;
  line-height: 18px !important;
  word-wrap: break-word !important;
}


/* History Table Row Styles */
.history-table-row {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  background: #EFF0F0 !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
  padding: 10px !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
  height: auto !important;
}


.history-table-cell {
  flex: 1 !important; /* Allow cells to grow and shrink */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 15px !important;
  font-family: 'Montserrat' !important;
  color: #0d1a26 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  white-space: normal !important;
  overflow-wrap: break-word !important;
  word-break: break-all !important;
  height: auto !important;
}



.history-table-cell:last-child {
  flex-grow: 2 !important; /* Allow the last cell to grow more */
  white-space: normal !important; /* Allow text to wrap */
  height: auto !important;
  overflow-wrap: break-word !important;
}

.copy-container {
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
  position: relative !important;
}

.copy-notification {
  z-index: 10000;
  position: absolute;
  top: -40px;
  font-family: "Irish Grover";
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(90deg, #AE2828 0%, #947B23 10%, #8AA421 20%, #1EAB35 30%, #1AA55A 40%, #1BCCB6 50%, #1966C0 60%, #671FAF 70%, #BB188D 80%, #DD124F 90%, #D80C0C 100%);
  color: rgb(255, 255, 255);
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  animation: popUp 0.5s ease-out;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis (...) if text overflows */
}


@keyframes popUp {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(20px);
  }
  50% {
    opacity: 1;
    transform: translateX(-50%) translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}


/* Specific Last Table Header Styling */
.MuiTableCell-head:last-child {
  text-align: center !important; /* Align the text to the right for the last header cell */
  padding-right: 15px !important; /* Add some right padding */
}

.table-container .MuiTableCell-root {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: visible; /* Hide any overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  /* grow with cell size */
  flex-grow: 1;
  /* center text */
  text-align: center;
  /* padding */
  margin: 10px;
}

@media (max-width: 768px) {
  .MuiTableCell-root {
    font-size: 12px; /* Smaller font for smaller screens */
    padding: 6px;
  }
}

.whatsapp-button {
  height: 40px !important;
  width: 40px !important;
  padding: 8px !important;
  margin-top: 20px !important;
  background: #25D366 !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  border: none !important;
}

.whatsapp-icon {
  width: 36px !important;
  height: 36px !important;
  fill: white !important;
}

/* Adjust the copy-container to align items */
.copy-container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* Adjust the existing copy-to-clipboard-button if needed */
.copy-to-clipboard-button {
  /* ... existing styles ... */
  height: 40px !important; /* Match the height of the WhatsApp button */
}

.history-selection-container {
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
  height: 40px !important;
  padding: 0 10px !important;
  margin-top: auto !important; /* Push to bottom */
  align-self: center !important; /* Center horizontally */
}

.history-selection-container input {
  width: 60px !important;
  height: 30px !important;
  border-radius: 15px !important;
  border: 1px solid #ccc !important;
  text-align: center !important;
  font-family: 'Montserrat' !important;
  font-size: 14px !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .add-player-button-container {
    padding: 0 5px !important;
  }

  .add-player-button,
  .copy-to-clipboard-button {
    font-size: 12px !important;
    padding: 0 15px !important;
    height: 36px !important;
  }

  .whatsapp-button {
    height: 36px !important;
    width: 36px !important;
    padding: 6px !important;
  }

  .history-selection-container {
    height: 36px !important;
  }

  .history-selection-container input {
    width: 50px !important;
    height: 26px !important;
    font-size: 12px !important;
  }
}

/* Add these styles for the menu container and sandwich menu */
.menu-container {
  position: absolute !important; /* Change from fixed to absolute */
  top: 20px !important;
  left: 20px !important;
  z-index: 1000 !important;
}

.menu-icon {
  font-size: 24px !important;
  cursor: pointer !important;
  color: #4a4b4d !important;
}

.sandwich-menu {
  position: absolute !important;
  top: 40px !important;
  left: 0 !important;
  background-color: white !important;
  border: 1px solid #dfe1e5 !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 150px !important;
  padding: 8px 0 !important;
}

.sandwich-menu button {
  background: none !important;
  border: none !important;
  padding: 8px 16px !important;
  text-align: left !important;
  width: 100% !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px !important;
  color: #333 !important;
  cursor: pointer !important;
  transition: background-color 0.2s ease !important;
}

.sandwich-menu button:hover {
  background-color: #f8f9fa !important;
}

/* Adjust App-header to account for menu */
.App-header {
  position: relative !important;
  width: 100% !important;
  padding-top: 20px !important;
  padding-bottom: 0px !important;
}

.admin-toggle {
  margin: 10px 0 !important;
  font-family: 'Montserrat', sans-serif !important;
  color: #1a2634 !important;
  opacity: 1 !important;
  transform: translateY(0) !important;
  transition: all 0.3s ease-in-out !important;
}

[data-theme='dark'] .admin-toggle {
  color: #cb645f !important;
}

.admin-toggle .MuiSwitch-root {
  margin-right: 8px !important;
  transition: all 0.3s ease-in-out !important;
}

.admin-toggle .MuiSwitch-track {
  background-color: #89949F !important;
  transition: background-color 0.3s ease-in-out !important;
}

.admin-toggle .MuiSwitch-thumb {
  background-color: #DCDEE0 !important;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out !important;
}

.admin-toggle .Mui-checked + .MuiSwitch-track {
  background-color: #1EAB35 !important;
  opacity: 0.5 !important;
  transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out !important;
}

.admin-toggle .Mui-checked .MuiSwitch-thumb {
  background-color: #1EAB35 !important;
  transition: background-color 0.3s ease-in-out !important;
}

/* Add these dark mode styles */
:root {
  --primary-color: #DCDEE0;
  --secondary-color: #89949F;
  --gradient-top-bottom: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
  --text-color: #0d1a26;
  --background-color: #ffffff;
}

[data-theme='dark'] {
  --primary-color: #2c3e50;
  --secondary-color: #34495e;
  --gradient-top-bottom: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
  --text-color: #ecf0f1;
  --background-color: #1a1a1a;
}

/* Add dark mode toggle button styles */
.dark-mode-toggle {
  position: absolute !important; /* Change from fixed to absolute */
  top: 20px !important;
  right: 20px !important;
  z-index: 1000 !important;
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  padding: 8px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: background-color 0.3s ease !important;
}

.dark-mode-toggle:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

[data-theme='dark'] .dark-mode-toggle:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode-icon {
  width: 24px !important;
  height: 24px !important;
  color: var(--text-color) !important;
  transition: transform 0.3s ease !important;
}

.dark-mode-toggle:hover .dark-mode-icon {
  transform: rotate(360deg) !important;
}

/* Update existing styles to use CSS variables */
body {
  background-image: var(--gradient-top-bottom) !important;
  color: var(--text-color) !important;
}

/* Add these to your existing styles */
[data-theme='dark'] .App-title {
  color: var(--text-color) !important;
}

[data-theme='dark'] .table-container,
[data-theme='dark'] .history-table-container {
  background: var(--primary-color) !important;
}

[data-theme='dark'] .MuiTableCell-root {
  color: var(--text-color) !important;
}

/* Update component styles to use variables */
.table-container, .history-table-container {
  background: var(--table-bg) !important;
}

.table-cell, .table-header-cell, .history-table-cell {
  background: var(--cell-bg) !important;
  color: var(--text-color) !important;
}

.add-player-button, .copy-to-clipboard-button {
  background: var(--button-bg) !important;
  color: var(--button-text) !important;
}

.add-katsching-button {
  background: var(--button-bg) !important;
}

.sandwich-menu {
  background-color: var(--cell-bg) !important;
  border-color: var(--border-color) !important;
}

.sandwich-menu button {
  color: var(--text-color) !important;
}

.menu-icon {
  color: var(--text-color) !important;
}

/* Add transition for smooth color changes */
* {
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease !important;
}

/* Update the root variables with dark mode alternatives */
:root {
  --primary-color: #DCDEE0;
  --secondary-color: #89949F;
  --gradient-top-bottom: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
  --text-color: #0d1a26;
  --background-color: #ffffff;
  --table-bg: #dcdee0;
  --cell-bg: #EFF0F0;
  --button-bg: #EFF0F0;
  --button-text: #0d1a26;
  --border-color: #ccc;
  --popup-bg: #BCECF5;
  --popup-input-bg: #EFF0F0;
  --popup-text: #304050;
  --success-color: #1EAB35;
  --delete-button: #ff4d4f;
  --delete-button-hover: #ff7875;
}

[data-theme='dark'] {
  --primary-color: #2c3e50;
  --secondary-color: #34495e;
  --gradient-top-bottom: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
  --text-color: #ecf0f1;
  --background-color: #1a1a1a;
  --table-bg: #2c3e50;
  --cell-bg: #34495e;
  --button-bg: #445566;
  --button-text: #ecf0f1;
  --border-color: #4a4a4a;
  --popup-bg: #2c3e50;
  --popup-input-bg: #34495e;
  --popup-text: #ecf0f1;
  --success-color: #2ecc71;
  --delete-button: #e74c3c;
  --delete-button-hover: #c0392b;
}

/* Update existing styles to use variables */
.table-container {
  background: var(--table-bg) !important;
}

.table-cell, .table-header-cell {
  background: var(--cell-bg) !important;
  color: var(--text-color) !important;
}

.add-player-button, .copy-to-clipboard-button {
  background: var(--button-bg) !important;
  color: var(--button-text) !important;
}

.add-katsching-button {
  background: var(--button-bg) !important;
}

/* Update popup styles */
.popup-content {
  background: var(--popup-bg) !important;
}

.popup-input, .popup-comment {
  background: var(--popup-input-bg) !important;
  color: var(--popup-text) !important;
}

/* Update table styles */
.MuiTableCell-head {
  background-color: var(--table-bg) !important;
  color: var(--text-color) !important;
}

.MuiTableCell-body {
  color: var(--text-color) !important;
}

/* Update success message */
.success-message {
  color: var(--success-color) !important;
}

/* Update delete button */
.delete-button {
  color: var(--delete-button) !important;
}

.delete-button:hover {
  color: var(--delete-button-hover) !important;
}

/* Add smooth transitions */
* {
  transition: background-color 0.3s ease, color 0.3s ease !important;
}

/* Update admin toggle styles for smoother transitions */
.admin-toggle {
  margin: 10px 0 !important;
  font-family: 'Montserrat', sans-serif !important;
  color: #1a2634 !important;
  opacity: 1 !important;
  transform: translateY(0) !important;
  transition: all 0.3s ease-in-out !important;
}

[data-theme='dark'] .admin-toggle {
  color: #cb645f !important;
}

/* Add transition for admin mode elements */
.add-player-button,
.delete-button,
.edit-button {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out !important;
}

/* When elements are hidden */
.add-player-button:not(:visible),
.delete-button:not(:visible),
.edit-button:not(:visible) {
  opacity: 0;
  transform: scale(0.95);
}

/* Smooth transition for admin-related table cells */
.actions-cell {
  transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out !important;
}

/* Update switch styles for smoother animation */
.admin-toggle .MuiSwitch-root {
  margin-right: 8px !important;
  transition: all 0.3s ease-in-out !important;
}

.admin-toggle .MuiSwitch-track {
  background-color: #89949F !important;
  transition: background-color 0.3s ease-in-out !important;
}

.admin-toggle .MuiSwitch-thumb {
  background-color: #DCDEE0 !important;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out !important;
}

.admin-toggle .Mui-checked + .MuiSwitch-track {
  background-color: #1EAB35 !important;
  opacity: 0.5 !important;
  transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out !important;
}

.admin-toggle .Mui-checked .MuiSwitch-thumb {
  background-color: #1EAB35 !important;
  transition: background-color 0.3s ease-in-out !important;
}

.clear-datastore-button {
  background-color: #dc3545 !important;
  color: white !important;
  margin-left: 10px !important;
  border-radius: 14px !important;
  opacity: 0.7 !important;
}

/* Add these new styles */
.username-greeting {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  color: var(--text-color);
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.username {
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 5px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.username:hover {
  background-color: var(--cell-bg);
}

.edit-icon {
  font-size: 0.8em;
  opacity: 0.7;
}

.username:hover .edit-icon {
  opacity: 1;
}
